import { FieldProps } from "firecms";

import { CustomTypeField } from "../components/TypeField/TypeField.component";
import { WebLinkType } from "../collections/events/events.constants";

/**
 * Collections field to represent social links.
 */
export const SocialLinksField = (props: FieldProps<number>) => (
  <CustomTypeField
    options={{
      [WebLinkType.facebook]: "Facebook",
      [WebLinkType.behance]: "Behance",
      [WebLinkType.codechef]: "Codechef",
      [WebLinkType.github]: "Github",
      [WebLinkType.instagram]: "Instagram",
      [WebLinkType.linkedin]: "Linkedin",
      [WebLinkType.other]: "Other",
      [WebLinkType.personal]: "Personal",
      [WebLinkType.twitter]: "Twitter",
    }}
    {...props}
  />
);
