import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

import { CustomTypeFieldProps } from "./types";

/**
 * Generic type field for CMS collection to modify field related UI behavior.
 */
export const CustomTypeField = <T extends string | number>({
  value,
  setValue,
  options,
  label = "Select Type",
}: CustomTypeFieldProps<T>) => {
  return (
    <FormControl variant="filled" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value ?? ""}
        onChange={(e) => setValue(e.target.value as T)}
        label={label}
      >
        {Object.entries(options).map(([key, itemLabel]) => (
          <MenuItem key={key} value={key}>
            <>{itemLabel}</>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
