export enum WebLinkType {
    facebook = 1,
    github = 2,
    linkedin = 3,
    personal = 4,
    twitter = 5,
    codechef = 6,
    behance = 7,
    instagram = 8,
    other = 100,
  }
